<template>
  <div class="accountWrap">
    <!-- 表单 -->
    <div class="facilityBox">
      <div class="topbtns">
        <el-button
          type="primary"
          size="small"
          icon="el-icon-download"
          :disabled="upData.length == 0 ? true : false"
          @click="bindFinal()"
          v-if="flag.activePage == 1"
          >下载结算单</el-button
        >
        <el-button
          type="primary"
          size="small"
          icon="el-icon-download"
          :disabled="!tableData.length"
          @click="bindWayBill()"
          v-else
          >下载运单</el-button
        >
      </div>
      <el-form
        class="manageForm"
        :model="params"
        :inline="true"
        v-if="flag.activePage == 1"
      >
        <el-form-item prop="EnterpriseFullName">
          <el-input
            placeholder="请输入内容"
            v-model="params.EnterpriseFullName"
          >
            <el-select v-model="params.currentEnterprise" slot="prepend">
              <el-option label="货主及服务商单位" value="1"></el-option>
              <el-option label="货主单位" value="2"></el-option>
              <el-option label="服务商单位" value="3"></el-option>
            </el-select>
          </el-input>
        </el-form-item>
        <el-form-item label="开票日期：" prop="date">
          <el-date-picker
            v-model="params.TaskDatetime"
            type="daterange"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="开票申请编号：" prop="InvoiceTaskID">
          <el-input
            class="fromInp"
            v-model="params.InvoiceTaskID"
            placeholder="输入开票申请编号"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="发票类型：" prop="InvoiceType">
          <el-select
            v-model="params.InvoiceType"
            clearable
            placeholder="请选择发票类型"
            @change="search()"
          >
            <el-option
              v-for="item in options"
              :key="item.Code"
              :label="item.Name"
              :value="item.Code"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="结算状态：" prop="IsChargeback">
          <el-select
            v-model="params.IsChargeback"
            clearable
            placeholder="请选择结算状态"
          >
            <el-option label="全部" value=""></el-option>
            <el-option label="退款" value="1"></el-option>
            <el-option label="正常" value="0"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label-width="10px">
          <el-button
            type="primary"
            size="medium"
            @click="search()"
            icon="el-icon-search"
            >搜索</el-button
          >
          <el-button
            type="primary"
            size="medium"
            @click="resetForm()"
            icon="el-icon-delete"
            >清空</el-button
          >
        </el-form-item>
      </el-form>
      <el-form class="manageForm" :model="params1" :inline="true" v-else>
        <el-form-item label="车牌：" prop="carNumber" label-width="96px">
          <el-input
            class="fromInp"
            v-model="params1.carNumber"
            placeholder="输入车牌号"
          ></el-input>
        </el-form-item>
        <!-- <el-form-item label="开票日期：" prop="chargebackTime">
          <el-date-picker v-model="params1.chargebackTime" type="daterange" value-format="yyyy-MM-dd"
            range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item> -->
        <el-form-item
          label="司机手机号："
          prop="driverPhone"
          label-width="96px"
        >
          <el-input
            class="fromInp"
            v-model="params1.driverPhone"
            placeholder="输入司机手机号"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="司机姓名：" prop="driverName" label-width="96px">
          <el-input
            class="fromInp"
            v-model="params1.driverName"
            placeholder="输入司机姓名"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="业务类型" prop="businessType">
          <el-select
            v-model="params1.businessType"
            placeholder="请选择业务类型"
            @change="businessChange"
            clearable
          >
            <el-option
              v-for="item in businessList"
              :key="item.Code"
              :label="item.Name"
              :value="item.Code"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="场景类型" prop="taskSceneTypeID">
          <el-select
            v-model="params1.taskSceneTypeID"
            placeholder="请选择场景类型"
            clearable
          >
            <el-option
              v-for="item in sceneList"
              :key="item.Code"
              :label="item.Name"
              :value="item.Code"
            ></el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item label="运单状态：" prop="WayBillBackStatus">
          <el-select v-model="params1.WayBillBackStatus" clearable placeholder="请选择运单状态">
            <el-option label="全部" value=""></el-option>
            <el-option label="正常" value="0"></el-option>
            <el-option label="退单" value="1"></el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item label-width="10px">
          <el-button
            type="primary"
            size="medium"
            @click="search()"
            icon="el-icon-search"
            >搜索</el-button
          >
          <el-button
            type="primary"
            size="medium"
            @click="resetForm()"
            icon="el-icon-delete"
            >清空</el-button
          >
          <!-- <el-button type="primary" size="medium" @click="chargebackWaybill" :disabled="!multipleSelection.length">勾选退单
          </el-button> -->
        </el-form-item>
      </el-form>
      <el-tabs v-model="flag.activePage" class="barWrap" @tab-click="resetForm">
        <el-tab-pane label="结算信息" name="1"></el-tab-pane>
        <el-tab-pane label="退款信息" name="2"></el-tab-pane>
      </el-tabs>
      <el-table
        v-show="flag.activePage == 1"
        :data="tableData"
        @select="selectChange"
        @select-all="selectChange"
        :header-cell-style="{ color: '#666', background: '#f0f0f0' }"
        v-loading="loading"
      >
        <el-table-column
          type="selection"
          align="center"
          width="55"
          fixed="left"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          type="index"
          align="center"
          label="序号"
          width="50"
          fixed="left"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="InvoiceTaskID"
          label="开票申请编号"
          width="180"
          fixed="left"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          align="center"
          prop="InvoiceType"
          label="发票类型"
          width="110"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="TaskType"
          label="业务类型"
          width="110"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="TaskSceneName"
          label="场景类型"
          width="150"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="ApplicantUserName"
          label="货主单位"
          width="180"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="PrincipalCompanyName"
          label="服务商单位"
          width="180"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="TaskDatetime"
          label="开票完成时间"
          width="140"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="InvoiceNumber"
          label="总计运单数量"
          width="110"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="Rate"
          label="费率"
          width="100"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          align="center"
          prop="TransportCosts"
          label="运费"
          width="100"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <div style="color: #ff8b17">
              {{ scope.row.TransportCosts | formatMoney }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="ExpensesOfTaxation"
          label="服务费/税费"
          width="100"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <div style="color: #ff8b17">
              {{ scope.row.ExpensesOfTaxation | formatMoney }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="NeedPayMoney"
          label="运单总金额"
          width="110"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <div style="color: #ff8b17">
              {{ scope.row.NeedPayMoney | formatMoney }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="BackTransportCosts"
          label="退单运费"
          width="100"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <div style="color: #ff8b17">
              {{ scope.row.BackTransportCosts | formatMoney }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="BackExpensesOfTaxation"
          label="退单服务费/税费"
          width="150"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <div style="color: #ff8b17">
              <span>{{ scope.row.BackExpensesOfTaxation | formatMoney }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="BackNeedPayMoney"
          label="退单总金额"
          width="110"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <div style="color: #ff8b17">
              {{ scope.row.BackNeedPayMoney | formatMoney }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="BackRealAmount"
          label="实际总金额"
          width="110"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <div style="color: #ff8b17">
              {{ scope.row.BackRealAmount | formatMoney }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" fixed="right" width="100">
          <template slot-scope="scope">
            <el-button
              type="primary"
              size="small"
              @click="goFacility(scope.row)"
              icon="el-icon-tickets"
              >详情</el-button
            >
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          label="凭证管理(线下付款)"
          fixed="right"
          width="150"
        >
          <template slot-scope="scope">
            <el-button
              type="primary"
              size="small"
              @click="openVoucherDialog(scope.row)"
              icon="el-icon-picture"
              :disabled="!scope.row.PayTaskID"
              >凭证管理
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-table
        v-show="flag.activePage == 2"
        :data="tableData"
        v-loading="loading"
      >
        <el-table-column
          type="index"
          align="center"
          fixed="left"
          label="序号"
          width="50"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="WaybillID"
          label="运单号（自动生成，请勿填写）"
          width="210"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="CarriageBillID"
          label="客户单号"
          width="180"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="InvoiceTypeName"
          label="发票类型"
          width="110"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="TaskType"
          label="业务类型"
          width="110"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="TaskScenceTypeName"
          label="场景类型"
          width="150"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="WaybillStatusName"
          label="运单状态"
          width="100"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="GoodsOwnerName"
          label="货主单位"
          width="200"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="DriverName"
          label="司机姓名"
          width="100"
          show-overflow-tooltip
        >
          <!-- <template slot-scope="scope">
            <div style="color: blue; cursor: pointer" @click="checkUser(scope.row)">
              {{ scope.row.DriverName }}
            </div>
          </template> -->
        </el-table-column>
        <el-table-column
          align="center"
          prop="DriverPhone"
          label="司机手机号码"
          width="110"
          show-overflow-tooltip
        >
          <!-- <template slot-scope="scope">
            <div style="color: blue; cursor: pointer" @click="checkUser(scope.row)">
              {{ scope.row.DriverPhone }}
            </div>
          </template> -->
        </el-table-column>
        <el-table-column
          align="center"
          prop="DriverIDCard"
          label="司机身份证号码"
          width="150"
          show-overflow-tooltip
        >
          <!-- <template slot-scope="scope">
            <div style="color: blue; cursor: pointer" @click="checkUser(scope.row)">
              {{ scope.row.DriverIDCard }}
            </div>
          </template> -->
        </el-table-column>
        <el-table-column
          align="center"
          prop="PayeeName"
          label="收款人姓名"
          width="100"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="PayeePhone"
          label="收款人手机号码"
          width="120"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="PayeeIDCard"
          label="收款人身份证号码"
          width="150"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="LeaderName"
          label="车队长姓名"
          width="100"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="LeaderPhone"
          label="车队长手机号码"
          width="120"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="LeaderIDCard"
          label="车队长身份证号码"
          width="150"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="CarNumber"
          label="车牌"
          width="100"
          show-overflow-tooltip
        >
          <!-- <template slot-scope="scope">
            <div style="color: blue; cursor: pointer" @click="checkInfo(scope.row)">
              {{ scope.row.CarNumber }}
            </div>
          </template> -->
        </el-table-column>
        <el-table-column
          align="center"
          prop="LoadNetWeight"
          label="装货净重（吨）"
          width="120"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="GoodsName"
          label="货品名称"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          align="center"
          prop="GoodsUnitName"
          label="货品单位"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          align="center"
          prop="LoadingDatetime"
          label="装货时间"
          width="160"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="SignDatetime"
          label="签收时间"
          width="160"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="OriginAddress"
          label="起始地"
          width="300"
          show-overflow-tooltip
        >
          <!-- <template slot-scope="scope">
            <div style="color: blue; cursor: pointer" @click="openMap(scope.row)">
              {{ scope.row.OriginAddress }}
            </div>
          </template> -->
        </el-table-column>
        <el-table-column
          align="center"
          prop="ArriveAddress"
          label="到达地"
          width="300"
          show-overflow-tooltip
        >
          <!-- <template slot-scope="scope">
            <div style="color: blue; cursor: pointer" @click="openMap(scope.row)">
              {{ scope.row.ArriveAddress }}
            </div>
          </template> -->
        </el-table-column>
        <el-table-column
          align="center"
          prop="DriverAllotCount"
          label="司机装货数量"
          width="100"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="DriverCarryPrice"
          label="司机运输单价(人民币)"
          width="200"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <div style="color: #ff8b17">
              {{ scope.row.DriverCarryPrice | formatMoney }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="CarriageTotalPrice"
          label="运费"
          show-overflow-tooltip
          width="130"
        >
          <template slot-scope="scope">
            <div style="color: #ff8b17">
              {{ scope.row.CarriageTotalPrice | formatMoney }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="ExpensesOfTaxation"
          label="服务费/税费"
          width="130"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <div style="color: #ff8b17">
              {{ scope.row.ExpensesOfTaxation | formatMoney }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="NeedPayMoney"
          label="运单总金额"
          width="130"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <div style="color: #ff8b17">
              {{ scope.row.NeedPayMoney | formatMoney }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="UnloadNetWeight"
          label="卸货净重（吨）"
          width="120"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="OriginalWayBillID"
          label="溯源运单号"
          width="180"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="Remark"
          label="风控路径"
          width="180"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          align="center"
          prop="Remark1"
          label="处理/驳回原因"
          width="200"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="ChargebackRemark"
          label="退款原因"
          width="200"
          show-overflow-tooltip
        >
        </el-table-column>
        <!-- <el-table-column align="center" prop="PayModeName" label="支付方式" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="InvoiceAmoutTypeName" label="开票金额" width="200" show-overflow-tooltip>
        </el-table-column> -->
      </el-table>
      <!-- 分页 -->
      <div style="margin-top: 10px">
        <el-pagination
          background
          class="pagination"
          @current-change="handleCurrentChange"
          :current-page.sync="pagination.page"
          :page-size="pagination.pagesize"
          layout="total, prev, pager, next, jumper"
          :total="pagination.total"
        >
        </el-pagination>
      </div>
    </div>
    <!-- 上传凭证dialog -->
    <el-dialog
      width="1200px"
      title="凭证"
      center
      :visible.sync="flag.voucherDialog"
      append-to-body
      v-if="flag.voucherDialog"
      :close-on-click-modal="false"
    >
      <EvidenceInfo :businessID="currentItem.InvoiceTaskID"> </EvidenceInfo>
    </el-dialog>
  </div>
</template>

<script>
import {
  tasksInfoList,
  taskSummary,
  upExport,
} from "@/api/finance/account/index";
import { GetBillList, DwonBillList } from "@/api/waybill/waybillManage/index";
import { getDataDict } from "@/api/common/common.js";
import { _downloadFile } from "@/utils/utils";
import EvidenceInfo from "@/components/businessCmpt/evidenceInfo";
import { getTaskScene } from "@/api/goodsOwner/goodsOwnerManage/basicInfo";
export default {
  name: "bossAccount",
  data() {
    return {
      businessList: [],
      // 发票类型数据
      options: [
        {
          Code: "",
          Name: "全部",
        },
      ],
      // 是否显示弹窗
      flag: {
        voucherDialog: false, //凭证dialog
        activePage: "1",
      },
      // 选择开票日期
      params: {
        TaskDatetime: ["", ""],
        InvoiceTaskID: "",
        EnterpriseFullName: "",
        currentEnterprise: "1",
        InvoiceType: "",
        IsChargeback: "",
      },
      params1: {
        taskSceneTypeID: "",
        businessType: "",
        waybillID: "",
        carriageBillID: "",
        driverName: "",
        driverPhone: "",
        carNumber: "",
        statusList: [20],
        chargebackTime: ["", ""],
      },
      //分页控件相关参数
      pagination: {
        page: 1, //当前所处的页码
        pagesize: 10, //每次请求的数量
        total: 0, //总条数
      },
      // 表单数据
      tableData: [],
      // 历史结算单汇总
      invoices: {},
      // 结算单下载数组
      upData: [],
      loading: true,
      currentItem: {},
      sceneList: [],
    };
  },
  created() {
    // this.pagination.page = this.$route.query.pageIndex ? Number(this.$route.query.pageIndex) : 1
    // this.params.TaskDatetime[0] = this.$route.query.StartDatetime ? this.$route.query.StartDatetime : ''
    // this.params.TaskDatetime[1] = this.$route.query.EndDatetime ? this.$route.query.EndDatetime : ''
    // this.params.InvoiceTaskID = this.$route.query.InvoiceTaskID ? this.$route.query.InvoiceTaskID : ''
    // this.params.currentEnterprise = this.$route.query.currentEnterprise ? this.$route.query.currentEnterprise : '1'
    // this.params.EnterpriseFullName = this.$route.query.EnterpriseFullName ? this.$route.query.EnterpriseFullName : ''
    // this.params.InvoiceType = this.$route.query.InvoiceType || ''
    // 获取结算信息列表
    this.tasksInfoList();
    // 汇总开票相关信息
    // this.taskSummary();
    // 获取发票类型
    getDataDict({ type: 20 }).then((res) => {
      this.options = this.options.concat(res.patterSetInfo);
    });
    //获取业务类型
    getDataDict({ type: 27 }).then((res) => {
      this.businessList = res.patterSetInfo;
    });
  },
  // mounted() {
  //   // 监听浏览器刷新
  //   window.addEventListener('beforeunload', e => this.beforeunloadHandler(e))
  // },
  // destroyed() {
  //   // 移除浏览器刷新
  //   window.removeEventListener('beforeunload', e => this.beforeunloadHandler(e))
  // },
  methods: {
    //业务类型改变
    businessChange(e) {
      this.params1.taskSceneTypeID = "";
      getTaskScene({ TaskTypeID: e }).then((res) => {
        this.sceneList = res.TaskTypeList.map((item) => {
          return {
            Code: item.TaskSceneTypeID,
            Name: item.TaskSceneName,
          };
        });
      });
    },
    //打开上传凭证弹窗
    openVoucherDialog(item) {
      this.currentItem = item;
      this.flag.voucherDialog = true;
    },
    // 浏览器刷新重置参数
    beforeunloadHandler(e) {
      if (this.$route.path == "/finance/account/index") {
        // 重置路由
        this.$router.push({
          path: "/finance/account/index",
        });
      }
    },
    // 下载结算单
    bindFinal() {
      this.loading = true;
      let data = {
        fileType: "结算单",
        fileName: "货主结算单",
        Title: [],
        InvoiceTaskIDs: [],
      };
      this.upData.forEach((item) => {
        let obj = {
          InvoiceTaskID: item.InvoiceTaskID,
        };
        data.InvoiceTaskIDs.push(obj);
      });
      upExport({ Json: JSON.stringify(data) })
        .then((res) => {
          for (let i in res.pathList) {
            _downloadFile(res.pathList[i].pathName);
            this.upData = [];
            this.tasksInfoList();
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // 下载运单
    bindWayBill() {
      this.loading = true;
      let data = {
        pageSize: this.pagination.pagesize,
        pageIndex: this.pagination.page,
        taskSceneTypeID: this.params1.taskSceneTypeID,
        waybillID: this.params1.waybillID,
        carriageBillID: this.params1.carriageBillID,
        driverName: this.params1.driverName,
        driverPhone: this.params1.driverPhone,
        carNumber: this.params1.carNumber,
        statusList: [20],
        // chargebackTimeStart: this.params1.chargebackTime ? this.params1.chargebackTime[0] : '',
        // chargebackTimeEnd: this.params1.chargebackTime ? this.params1.chargebackTime[1] : '',
      };
      DwonBillList(data)
        .then((res) => {
          window.location.href = res.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // 下载结算单
    selectChange(val) {
      this.upData = val;
    },
    // 条件搜索
    search() {
      this.pagination.page = 1;
      this.flag.activePage == "1" ? this.tasksInfoList() : this.getBillList();
    },
    // 清空搜索条件
    resetForm() {
      this.pagination.page = 1;
      this.params = {
        TaskDatetime: ["", ""],
        EnterpriseFullName: "",
        InvoiceTaskID: "",
        currentEnterprise: "1",
        InvoiceType: "",
        IsChargeback: "",
      };
      this.params1 = {
        taskSceneTypeID: "",
        businessType: "",
        waybillID: "",
        carriageBillID: "",
        driverName: "",
        driverPhone: "",
        carNumber: "",
        statusList: [20],
        chargebackTime: ["", ""],
      };
      this.flag.activePage == "1" ? this.tasksInfoList() : this.getBillList();
    },
    // 汇总开票相关信息
    taskSummary() {
      let data = {
        pageSize: this.pagination.pagesize,
        pageIndex: this.pagination.page,
        StartDatetime: this.params.TaskDatetime
          ? this.params.TaskDatetime[0]
          : "",
        EndDatetime: this.params.TaskDatetime
          ? this.params.TaskDatetime[1]
          : "",
        InvoiceTaskID: this.params.InvoiceTaskID
          ? this.params.InvoiceTaskID
          : "",
        EnterpriseFullName:
          this.params.currentEnterprise == 1
            ? this.params.EnterpriseFullName
            : "",
        DemanderName:
          this.params.currentEnterprise == 2
            ? this.params.EnterpriseFullName
            : "",
        SupplierName:
          this.params.currentEnterprise == 3
            ? this.params.EnterpriseFullName
            : "",
        RoleType: 0,
        InvoiceType: this.params.InvoiceType,
        IsChargeback: this.params.IsChargeback,
      };
      taskSummary({ Json: JSON.stringify(data) }).then((res) => {
        this.invoices = res.data;
      });
    },
    // 获取结算信息列表
    tasksInfoList() {
      this.loading = true;
      let data = {
        pageSize: this.pagination.pagesize,
        pageIndex: this.pagination.page,
        StartDatetime: this.params.TaskDatetime
          ? this.params.TaskDatetime[0]
          : "",
        EndDatetime: this.params.TaskDatetime
          ? this.params.TaskDatetime[1]
          : "",
        InvoiceTaskID: this.params.InvoiceTaskID
          ? this.params.InvoiceTaskID
          : "",
        EnterpriseFullName:
          this.params.currentEnterprise == 1
            ? this.params.EnterpriseFullName
            : "",
        DemanderName:
          this.params.currentEnterprise == 2
            ? this.params.EnterpriseFullName
            : "",
        SupplierName:
          this.params.currentEnterprise == 3
            ? this.params.EnterpriseFullName
            : "",
        RoleType: 0,
        InvoiceType: this.params.InvoiceType,
        IsChargeback: this.params.IsChargeback,
      };
      tasksInfoList({ Json: JSON.stringify(data) })
        .then((res) => {
          this.tableData = res.invoices;
          this.pagination.total = Number(res.totalRowCount);
          // this.taskSummary()
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // 获取退单列表
    getBillList() {
      this.loading = true;
      let data = {
        pageSize: this.pagination.pagesize,
        pageIndex: this.pagination.page,
        taskSceneTypeID: this.params1.taskSceneTypeID,
        waybillID: this.params1.waybillID,
        carriageBillID: this.params1.carriageBillID,
        driverName: this.params1.driverName,
        driverPhone: this.params1.driverPhone,
        carNumber: this.params1.carNumber,
        statusList: [20],
        // chargebackTimeStart: this.params1.chargebackTime ? this.params1.chargebackTime[0] : '',
        // chargebackTimeEnd: this.params1.chargebackTime ? this.params1.chargebackTime[1] : '',
      };
      GetBillList(data)
        .then((res) => {
          this.tableData = res.data.DataList;
          this.pagination.total = Number(res.data.TotalCount);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    //分页控件页码change事件回调
    handleCurrentChange(e) {
      this.pagination.page = e;
      this.flag.activePage == "1" ? this.tasksInfoList() : this.getBillList();
    },
    //查看结算单详情
    goFacility(item) {
      this.$router.push({
        path: "/finance/account/accountInfo",
        query: {
          InvoiceTaskID: item.InvoiceTaskID,
          pageIndex: this.pagination.page,
          StartDatetime: this.params.TaskDatetime
            ? this.params.TaskDatetime[0]
            : "",
          EndDatetime: this.params.TaskDatetime
            ? this.params.TaskDatetime[1]
            : "",
          currentEnterprise: this.params.currentEnterprise,
          EnterpriseFullName: this.params.EnterpriseFullName
            ? this.params.EnterpriseFullName
            : "",
          InvoiceType: this.params.InvoiceType,
        },
      });
    },
  },
  components: {
    EvidenceInfo,
  },
};
</script>

<style lang="scss">
.accountWrap .el-select .el-input {
  width: 130px;
}
</style>

<style lang="scss" scoped>
.accountWrap {
  .topbtns {
    padding-bottom: 10px;
    margin: 0px 0px 10px;
    border-bottom: 1px solid #eee;
  }

  .settleBox {
    margin-bottom: 10px;
    padding: 10px 16px;
    background-color: #f0f0f0;
    color: #666;
  }

  .accountTitle {
    margin: 20px 0 0 0;
    font-size: 24px;
    text-align: center;

    .accountInfo {
      width: 25vw;
      background: #f2f2f2;
      padding: 10px;
      border-radius: 10px;
      margin: 20px 0 0 0;
      font-size: 16px;
      line-height: 30px;
    }
  }
}

.settleBox {
  line-height: 32px;
  padding: 0px 16px;

  div {
    div {
      span {
        vertical-align: top;
        display: inline-block;
        margin: -1px 0 0 0;
      }
    }
  }
}
</style>
